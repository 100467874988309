body {
  min-width: 320px;
}

p {
  padding-bottom: 0.1em;
  font-size: 1em;
}
.upperNavBar {
  min-height: 20px;
  background-color: #248fca;
  color: white;
  margin: 0;
  padding: 0;
}
.langSwitcher {
  background-color: #1B5C8A;
  color: white;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  padding: 0;
  min-height: 20px;
}
@media (max-width: 768px) {
  .langSwitcher {
    font-size: 0.6em;
  }
}

.blancTransparent {
  background-color: rgba(255, 255, 255, 0.8);
}
.masthead {
  /*height: 70vh;*/
  min-height: 500px;
  max-height: 700px;
  background-image: url("/img/mainPicture.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.masthead-Short {
  /*height: 70vh;*/
  min-height: 300px;
  max-height: 400px;
  background-image: url("/img/mainPicture.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.sc-masthead-Short {
  /*height: 70vh;*/
  min-height: 300px;
  max-height: 400px;
  background-image: url("/img/main_7904.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.fe-masthead-Short {
  /*height: 70vh;*/
  min-height: 300px;
  max-height: 400px;
  background-image: url("/img/main_8033.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.fondContenuBlancEtBandeBleu {
  background-color: white;
  padding: 20px;
  border-left: 5px solid #248fca;
}
.fondContenuBlancSANSBandeBleu {
  background-color: white;
  padding: 20px;
}
.fondContenuCremeEtBandeBleu {
  background-color: #e4e2df;
  padding: 20px 60px;
  border-left: 5px solid #248fca;
}
.fondFooter {
  border-bottom-right-radius: 0.4125rem;
  border-bottom-left-radius: 0.4125rem;
  background-color: white;
  min-height: 10px;
}
.typoBleuHardy {
  color: #065b8e;
  font-size: 0.75em;
  line-height: 1.35em;
  padding: 0;
  margin-top: 0;
  margin-bottom: 9px;
}
.typoBleuHardy a:link {
  text-decoration: none;
  font-weight: 600;
  color: #065b8e;
}
.typoBleuHardy a:hover {
  text-decoration: none;
  font-weight: 600;
  color: #065b8e;
}
.typoBleuHardy a:visited {
  text-decoration: none;
  font-weight: 600;
  color: #065b8e;
}
.typoBleuHardy a:active {
  text-decoration: none;
  font-weight: 600;
  color: #065b8e;
}
h4 {
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 600;
}
.texteDescription {
  font-size: 1.5em;
  line-height: 1.35em;
  font-weight: 400;
  color: white;
}
@media (max-width: 768px) {
  .texteDescription {
    font-size: 1em;
  }
}

.specialites {
  font-size: 1em;
  line-height: 1.65em;
  color: white;
}
.filetVerticaux {
  color: #cfb48d;
  padding-left: 9px;
  padding-right: 9px;
}
.footerDisclaimer {
  text-align: center;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.fondBrunAccueil {
  background-color: rgba(60, 40, 29, 0.7);
  padding: 10px;
}

.boitePresentation {
  background-color: white;
  /*padding: 9px;*/
}
.shadow-STD {
  box-shadow: 0 0 9px rgba(140, 152, 164, 0.75) !important;
}
.learnMore {
  font-size: 0.75em;
  line-height: 1.25em;
  color: #666666;
  padding-bottom: 0;
  margin-bottom: 0;
}
.learnMore a:link {
  text-decoration: none;
  color: #666666;
}
.learnMore a:hover {
  text-decoration: none;
  color: #666666;
}
.learnMore a:visited {
  text-decoration: none;
  color: #666666;
}
.learnMore a:active {
  text-decoration: none;
  color: #666666;
}
.learnMore a:after {
  position: absolute; /* Prevent underline of arrow */
  padding-left: 3px; /* Add a little space between text and arrow */
  /*content: "\00bb";*/ /* Unicode hex for &raquo; */
  content: ">"; /* Unicode hex for &raquo; */
  font-size: 1em;
  font-weight: 800;
  color: #666666;
}

/* CONTACT */
.contactCity {
  font-size: 1.05em;
  line-height: 1.3em;
  font-weight: 600;
  color: #248fca;
}

/* TABLES */
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1e2022;
}

th,
td {
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1.25em;
  padding: 0.5rem;
  vertical-align: top;
  border-bottom: 3px solid white;
}

td:nth-child(1) {
  width: 40%;
}

td:nth-child(2), td:nth-child(3) {
  width: 20%;
}

td:nth-child(4) {
  width: 20%;
}

tbody {
  border-bottom: "3px" solid white;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    font-size: 0.6em;
    font-weight: 400;
    line-height: 1.1em;
    padding: 0.15rem;
    vertical-align: top;
    border-bottom: 1px solid white;
  }
}

/* - - - PAGES DE FORMATION - SYLLABUS - - - */

.soutitre {
  font-size: 1.05em;
  line-height: 1.3em;
  font-weight: 600;
  color: #248fca;
  margin-bottom: 4px;
}
li {
  font-size: 0.9em;
}
.information {
  padding: 0.5em;
  background-color: #7ed9ff;
  margin-top: 50px;
}
.legal {
  text-align: center;
  font-style: italic;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

/* Pages des formations */
.titreCours {
  font-size: 1.05em;
  line-height: 1.3em;
  font-weight: 600;
  color: #248fca;
  margin-bottom: 2px;
  margin-top: 4px;
}

/* - - - PAGES DE CONTENU  - - - */
.introduction {
  font-size: 1.1em;
  line-height: 1.3em;
  font-weight: 600;
  color: #248fca;
  margin-bottom: 0;
  margin-top: 4px;
}
.textePetit {
  font-size: 0.9em;
  line-height: 1.3em;
  font-style: italic;
}
.dateMAJ {
  text-align: center;
  font-style: italic;
  font-size: 0.65rem;
  color: rgba(0, 0, 0, 0.6);
}

.container {
  width: 100%;
  padding-right: 55px;
  padding-left: 55px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 55px;
  padding-left: 55px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* GENERIC */
ul {
  list-style-type: square;
  line-height: 1.35em;
}

a:link {
  color: #333333;
}
a:visited {
  color: #333333;
}
a:hover {
  color: #333333;
}
a:active {
  color: #333333;
}

.row.home {
  text-align: center;
}

@media screen and (max-width: 768px) {

  .fondContenuCremeEtBandeBleu {
    padding: 20px 5px;
  }

  .container-fluid {
    padding: 0;
  }

}